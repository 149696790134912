export default {
  async getCodeByPhone({ commit }, payload) {
    const formData = new FormData()

    formData.append('Smsreg[phone]', payload.phone)
    formData.append('Smsreg[exist]', payload.exist)

    return await this.$axios.$post('/user-api/phone-verification/', formData)
  },

  async sendPhoneCode({ commit }, payload) {
    const formData = new FormData()

    formData.append('Smsreg[phone]', payload.phone)
    formData.append('Smsreg[code]', payload.code)

    return await this.$axios.$post('/user-api/phone-verification/', formData)
  },

  async getCodeByEmail({ commit }, payload) {
    const formData = new FormData()
    formData.append('email', payload.email)
    return await this.$axios.$post('/api-v2/user/email', formData)
  },

  async sendEmailCode({ commit }, payload) {
    const formData = new FormData()

    formData.append('id', payload.id)
    formData.append('code', payload.code)

    return await this.$axios.$post('/api-v2/user/email/confirm', formData)
  },

  async userRegistration({ commit }, payload) {
    const formData = new FormData()

    formData.append('User[phone]', '77077077070')
    formData.append('User[email]', payload.email)
    formData.append('User[first_name]', payload.first_name)
    formData.append('User[last_name]', payload.last_name)
    formData.append('User[password]', payload.password ? payload.password : '')
    formData.append('User[passwordConfirm]', payload.passwordConfirm ? payload.passwordConfirm : '')
    formData.append('User[is_guest]', payload.isGuest ? 1 : 0)
    formData.append('User[cartId]', payload.cartId)

    return await this.$axios.$post('/user-api/new/', formData)
  },
  async newUserCreateOrder({ commit }, payload) {
    const formData = new FormData()

    formData.append('User[phone]', payload.phone)
    formData.append('User[email]', payload.email)
    formData.append('User[full_name]', payload.name)
    formData.append('User[password]', payload.password ? payload.password : '')
    formData.append('User[passwordConfirm]', payload.passwordConfirm ? payload.passwordConfirm : '')
    formData.append('User[is_guest]', payload.isGuest ? 1 : 0)
    formData.append('User[cartId]', payload.cartId)

    return await this.$axios.$post('/user-api/new-user-fast-create-order', formData)
  },

  async userAuthorization({ commit }, payload) {
    const formData = new FormData()

    formData.append('User[phone]', payload.phone)
    formData.append('User[password]', payload.password)

    return await this.$axios.$post('/user-api/login/', formData)
  },

  async updateUserPassword({ commit }, payload) {
    const formData = new FormData()

    formData.append('User[phone]', payload.phone)
    formData.append('User[passwordConfirm]', payload.passwordConfirm)
    formData.append('User[password]', payload.password)

    return await this.$axios.$post('/user-api/password-recovery-sms/', formData)
  },
}
