export default function (ctx) {
  ctx.$axios.onError((error) => {
    const code = error.code || error.response?.status

    if (code >= 500) {
      ctx.redirect('/error')
    } else if (code === 401) {
      ctx.$auth.logout()
      console.log(ctx.$auth)
    } else if (code >= 400 || code < 500) {
      ctx.$services.common.error.onError(code)
    }

    if (code === 401) {
      ctx.$auth.logout()
      ctx.redirect('/')
    }
  })
}
