import { Context } from '@nuxt/types'
import { IBasketProduct, IBasketProductRaw, IProductPrice } from 'types/DTO/basket'
import { IProductEventGa } from 'types/DTO/products'

export default class ProductInBasket {
  constructor(
    private $store: any,
    private $services: Context['$services'],
  ) {}

  async getBasketProducts(basketId: string) {
    await this.$store.dispatch('rph-basket/getBasketProducts', {
      id: basketId,
      params: {
        params: {
          expand:
            'basketProducts,basketProducts.product,basketProducts.product.prices,basketProducts.product.images,basketProducts.product.category,basketProducts.product.specification,basketProducts.product.extra,basketProducts.product.producers',
        },
      },
    })
  }

  async addProductInBasket(
    basketId: string,
    product_id: string,
    count: number,
    pharmacy_id: number,
    is_hot: number,
    provider_id: number,
    additionalKey: string,
  ) {
    const origin_uuid = this.getAdditionalSource(additionalKey, null)?.id
    if (basketId !== 'null') {
      const addProduct = await this.$store.dispatch('rph-basket/addToBasket', {
        basket_id: basketId,
        product_id,
        count,
        pharmacy_id,
        is_hot,
        provider_id,
        origin_uuid,
        additionalKey,
      })
      if (addProduct) {
        await this.getBasketProducts(basketId)
        this.cartPushEvent('add_to_cart')
      }
    } else {
      const res = await this.$store.dispatch('rph-basket/createBasket')
      if (res && res.id) {
        localStorage.setItem('basketId', String(res.id))
        await this.addProductInBasket(
          res.id,
          product_id,
          count,
          pharmacy_id,
          is_hot,
          provider_id,
          additionalKey,
        )
      }
    }
  }

  async deleteProductInBasket(
    basketId: string,
    product_id: string,
    pharmacy_id: number,
    provider_id: number | '',
  ) {
    const deleteProduct = await this.$store.dispatch('rph-basket/deleteById', {
      id: basketId,
      product_id,
      pharmacy_id,
      provider_id,
    })
    if (deleteProduct) {
      this.deleteProductInStorage(product_id)
    }
  }

  async concatBasket(basketId: string) {
    const concatBasket = await this.$store.dispatch('rph-basket/concatBasket', {
      id: basketId,
      params: {
        params: {},
      },
    })
    if (concatBasket && concatBasket?.data.id) {
      return concatBasket.data.id
    }
  }

  deleteProductInStorage(id: string) {
    const products = this.$store.getters['rph-basket/basketProductsId'].split(',')
    const tailProducts = products.filter((item: string) => String(item) !== String(id)).join()
    this.updateStorage(tailProducts)
  }

  updateStorage(products: string): void {
    this.$store.commit('rph-basket/setBasketProductsId', products)
  }

  adapt(data: any): IBasketProduct[] {
    const adapted: IBasketProduct[] = []
    const { id, basketProducts } = data
    basketProducts.forEach((item: IBasketProductRaw) => {
      return adapted.push(this.adaptItem(item, id))
    })

    return adapted
  }

  // Скрыта предыдущая обработка вывода цены на случай, если вернется
  // getSelectedPrice(item: any): string {
  //   if (item.pharmacy_id && !item.provider_id) {
  //     return 'fast_delivery'
  //   } else if (item.pharmacy_id && item.is_hot) {
  //     return 'hot_price'
  //   } else {
  //     return 'standard'
  //   }
  // }
  // Хардкод чтобы не переписывать всю логику получения цены на случай, если вернется старая
  getSelectedPrice(item: any): string {
    return 'standard'
  }

  adaptItem(item: IBasketProductRaw, id: number): IBasketProduct {
    const { product } = item
    return {
      count: item.count,
      basketId: id,
      id: product.id,
      is_hot: item.is_hot,
      pharmacy_id: item.pharmacy_id,
      provider_id: item.provider_id,
      title: product.title,
      slug: product.slug,
      short_title: product.short_title,
      seo_text: product.seo_text,
      category_id: product.category_id,
      pickup_only: product.pickup_only,
      images: product.images,
      prices: product.prices,
      category: product.category,
      producers: product.producers,
      selected_price: this.getSelectedPrice(item),
      is_recipe: product.specification ? product.specification.recipe > 0 : false,
      mnn: product.specification?.mnn.length ? product.specification.mnn[0].title : '',
      dosage: product.specification ? product.specification.dosage : '',
      origin_uuid: item.origin_uuid ? +item.origin_uuid : 1,
    }
  }

  adaptPrice(data: any) {
    const adaptedPrice: IProductPrice[] = []
    const { basketProducts } = data

    data.forEach((item: IBasketProduct) => {
      return adaptedPrice.push(this.adaptPriceItem(item))
    })

    return adaptedPrice
  }

  getPrice(product: any) {
    const { hot_price, fast_delivery, standard } = product.prices
    hot_price.type = 'hot'
    fast_delivery.type = 'fast'
    standard.type = 'standard'
    return (hot_price.price && hot_price) || (fast_delivery.price && fast_delivery) || standard
  }

  getPharmacyId(product: any) {
    const price = this.getPrice(product)
    const { type } = price
    switch (type) {
      case 'hot':
        return {
          isHot: 1,
          pharmacyId: price.pharmacy_id,
          providerId: price.provider_id || '',
        }
      case 'fast':
        return {
          isHot: 0,
          pharmacyId: price.pharmacy_id,
          providerId: '',
        }
      case 'standard':
        return {
          isHot: 0,
          pharmacyId: price.pharmacy_id,
          providerId: price.provider_id || '',
        }
      default:
        return {
          isHot: 0,
          pharmacyId: price.pharmacy_id,
        }
    }
  }

  adaptPriceItem(item: IBasketProduct): IProductPrice {
    const selected_price = this.getSelectedPrice(item)
    // @ts-ignore
    const { price, discount } = item.prices[selected_price]
    return {
      id: item.id,
      count: item.count,
      price,
      discount,
    }
  }

  cartPushEvent(eventKey: string) {
    const eventItem = this.adaptGaEventItem(this.$store.getters['rph-basket/basketList'])
    this.$store.$gtm.push({ event: eventKey, ...eventItem })
  }

  adaptGaEventItem(products: IBasketProduct[]): IProductEventGa {
    const eventItems = products.map((product: IBasketProduct, index: number) => {
      const priceData = this.getPrice(product)
      return {
        item_id: product.id,
        item_name: product.short_title,
        discount: priceData.discount,
        index: index + 1,
        item_brand: product.producers.title,
        item_category: product.category?.title,
        quantity: product.count,
        price: priceData.price,
        item_list_id: product.origin_uuid,
        item_list_name: this.getAdditionalSource(null, product.origin_uuid)?.title,
      }
    })
    return {
      currency: 'KZT',
      coupon: null,
      value: this.totalBasketPrice(),
      ecommerce: {
        items: eventItems,
      },
    }
  }

  totalBasketPrice(): number {
    const products = this.$store.getters['rph-basket/productsPrice']
    return (
      this.$store.$services.basket.calc.total(products) -
      this.$store.$services.basket.calc.discount(products) -
      this.$store.getters['rph-order/promoCodeDiscount']
    )
  }

  getAdditionalSource(key: string | null, id: number | null) {
    const additionSources = [
      {
        id: 1,
        title: 'Каталог',
        key: 'category',
      },
      {
        id: 2,
        title: 'Страница товара',
        key: 'product-page',
      },
      {
        id: 3,
        title: 'Аналог товара',
        key: 'product-analogue',
      },
      {
        id: 4,
        title: 'Страница аптеки',
        key: 'pharmacy',
      },
      {
        id: 5,
        title: 'Страница новости ',
        key: 'news',
      },
      {
        id: 6,
        title: 'Страница МНН',
        key: 'inn',
      },
      {
        id: 7,
        title: 'Страница производителя',
        key: 'producer',
      },
      {
        id: 8,
        title: 'Главная',
        key: 'home',
      },
      {
        id: 9,
        title: '404 ошибка',
        key: 'not-found',
      },
      {
        id: 10,
        title: 'Корзина (Отдельный блок)',
        key: 'cart',
      },
      {
        id: 11,
        title: 'Страница результатов поиска',
        key: 'search',
      },
    ]
    return additionSources.find((source) => {
      return key ? source.key === key : id ? source.id === id : additionSources[0]
    })
  }
}
